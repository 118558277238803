import GymJunkieLogo from '@images/gymJunkieLogo.svg'
import { Layout } from '@styles/layout'
import { ContentWrapper, MOBILE_BREAK_POINT } from '@styles/styles'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { privacyPolicy } from './assets/privacyPolicyData'

export const PrivacyPolicy = () => {
  return (
    <Layout>
      <Wrapper>
        <Container>
          <NavbarWrapper>
            <NavbarSubContainer alignLeft>
              <NavbarNavigationLink to='/'>Home</NavbarNavigationLink>
            </NavbarSubContainer>
            <NavbarSubContainer>
              <PageHeaderText>Privacy Policy</PageHeaderText>
            </NavbarSubContainer>
            <NavbarSubContainer alignRight>
              <NavbarLogo src={GymJunkieLogo} />
            </NavbarSubContainer>
          </NavbarWrapper>
          <BodyContainer>
            {privacyPolicy.map((section, i) => (
              <SectionContainer key={i}>
                {section.header && (
                  <SectionHeader>{section.header}</SectionHeader>
                )}
                {section.body && (
                  <SectionBodyText>{section.body}</SectionBodyText>
                )}
                {!section.links ? null : (
                  <SectionLinksContainer>
                    {section.links.map((link) => (
                      <LinkContainer key={link.text}>
                        <LinkText
                          href={link.url}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {link.text}
                        </LinkText>
                      </LinkContainer>
                    ))}
                  </SectionLinksContainer>
                )}
              </SectionContainer>
            ))}
          </BodyContainer>
        </Container>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  /* background-color: teal; */
`

const NavbarWrapper = styled.div`
  width: 100%;
  height: 80px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  align-items: center;
  justify-content: center;
`

interface SubContainerProps {
  alignLeft?: boolean
  alignRight?: boolean
}

const NavbarSubContainer = styled.div<SubContainerProps>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: ${(props) =>
    props.alignLeft ? 'flex-start' : props.alignRight ? 'flex-end' : 'center'};
`

const NavbarNavigationLink = styled(Link)`
  font-family: ${(props) => props.theme.fontFamily.special};
  font-size: ${(props) => props.theme.fontSize.desktop.large}em;
  color: ${(props) => props.theme.colors.secondary};
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  transition: 250ms;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.fontSize.mobile.large}em;
  }
`

const PageHeaderText = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.special};
  font-size: ${(props) => props.theme.fontSize.desktop.header}em;
  text-align: center;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.fontSize.mobile.header}em;
  }
`

const NavbarLogo = styled.img`
  height: 30px;
  width: auto;
`

const Container = styled(ContentWrapper)`
  padding: 20px ${(props) => props.theme.paddings.content}px;
  /* background-color: lightsalmon; */
`

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-top: 3em;
`

const SectionContainer = styled.div`
  /* text-align: center; */
  margin-bottom: 2.5em;

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    text-align: center;
  }
`

const SectionHeader = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.normal};
  font-size: ${(props) => props.theme.fontSize.desktop.large}em;
  margin-bottom: 0.5em;

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.fontSize.mobile.large}em;
  }
`

const SectionBodyText = styled.p`
  font-family: ${(props) => props.theme.fontFamily.normal};
  font-size: ${(props) => props.theme.fontSize.desktop.body}em;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.fontSize.mobile.body}em;
  }
`

const SectionLinksContainer = styled.div`
  margin-top: 1em;
`

const LinkContainer = styled.div`
  margin-bottom: 1em;
`

const LinkText = styled.a`
  /* display: block; */
  font-family: ${(props) => props.theme.fontFamily.normal};
  font-size: ${(props) => props.theme.fontSize.desktop.body}em;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  transition: 250ms;
  &:hover {
    color: ${(props) => props.theme.colors.secondary};
  }

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    font-size: ${(props) => props.theme.fontSize.mobile.body}em;
  }
`
